import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { Section } from '../components/section';
import { Anchors } from '../components/navigation';
import styles from './index.module.scss';

const IndexPage = ({ location }: { location: Location }) => (
  <Layout location={location}>
    <SEO title="SimpleSoft software house - DevOps job" description="job offer, DevOps, Azure, Terraform, Gitlab, SQL Server, Linux, Kubernetes" />
    <Section anchor={''}>
      <h1>DevOps</h1>
        <h3>Brief project description:</h3>
        <p>We are creating selling platform that allows users to buy, sell and exchange event tickets. 
        Platform has some simple and more advanced functions like monitoring real time prices on the market. 
        Currently the project is in its end stage of first version and we are just about to go live. 
        Platform is written by us from scratch and contains three main modules: user Portal, backend services and CMS/admin panel. 
        Project is created to be scalable and well performing. We are using various technologies depending on the module:
        </p>
        <ul>
            <li> React,  Redux, Angular, TypeScript </li>
            <li>.Net core, C#, F#, </li>
            <li>Docker, Kubernetes, Azure, Service Bus, SQL server</li>
        </ul>
        <h3>Job description</h3>
        <p>
        We are looking for a person responsible for auditing existing solution and maintaining production environment. 
        Project is hosted on Azure and requires some adjustments and monitoring set up. 
        Part of the long term responsibilities will be overseeing production environment, identify and act on opportunities to improve and update software and systems.
        </p>
        <h4>Requirements and Experience: (essential)</h4>
        <ul>
          <li>Proven auditing experience over existing solutions and helping with developing new ones</li>
          <li>Experience with supporting production environment</li>
        </ul>
        <h4>Tech stack:</h4>
        <ul>
          <li>Azure, application insights, load balancing,</li>
          <li>Docker, Kubernetes</li>
          <li>SQL server, node, .NET, ngnix</li>
        </ul>
    </Section>
  </Layout>
);

export default IndexPage;
